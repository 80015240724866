import styled from 'styled-components/macro';
import { Button, type } from '@candidco/enamel';
import { Form } from 'formik';
import CloseSvg from 'assets/close.svg?react';

export const Header = styled.div`
  display: flex;

  justify-content: space-between;
  
  padding: 24px; 32px;
  box-shadow: 0px -1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const HeaderTitle = styled(type.H4)`
  margin-bottom: 0;
  line-height: 20px;
`;

export const StyledCloseButton = styled(CloseSvg)`
  fill: ${({ theme }) => theme.colors.black50}};
  height: 24px;
  width: 24px;
`;

export const ModalContainer = styled.div`
  width: 640px;
  height: 100%;
  max-height: 90vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;

export const ModalBody = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  overflow: auto;
`;

export const SectionHeader = styled(type.H4)`
  margin-bottom: 12px;
`;

export const ProductSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ProductButtonContainer = styled.button<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  border: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? theme.colors.blue50 : theme.colors.black20};
  border-radius: 12px;
  padding: 24px;
`;

export const ProductButtonDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  margin: auto;
`;

export const ProductDescriptionHeader = styled(type.H5)`
  margin-bottom: 0;
`;

export const ProductDescriptionBodyText = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black70};
  text-align: left;
`;

export const PatientInfoForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PatientInfoFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;

  > * {
    flex: 1;
    border-radius: 4px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  padding: 24px; 32px;
  box-shadow: 0px 1px 0px 0px ${({ theme }) => theme.colors.black20} inset;
`;

export const StyledCancelButton = styled(Button)`
  color: ${({ theme }) => theme.colors.black70};
`;

export const StyledImage = styled.img`
  width: 180px;
  height: 140px;
  @media ${({ theme }) => theme.mediaQueries.mobile} {
    width: 96px;
    height: 75px;
  }
`;
