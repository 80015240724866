import { useContext, useState, useEffect } from 'react';
import { ZendeskContext } from '@candidco/enamel';
import { getBrandDomainSettings } from 'utils/brands';

export enum WidgetVersion {
  Classic = 'classic',
  Messenger = 'messenger',
}
/**
 * Custom wrapper for the Zendesk Web Widget to assist with the migration from the current
 * classic widget to the new widget that has breaking changes.  Once this is verified to work
 * we can remove this code and port it over to Enamel's implementation of the Zendesk widget.
 */
const useZendesk = () => {
  const { showZendesk } = getBrandDomainSettings();
  const zendeskClassicProps = useContext(ZendeskContext);
  const { isWidgetLoaded } = zendeskClassicProps;
  const [widgetVersion, setWidgetVersion] = useState<string | null>(null);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  useEffect(() => {
    if (!isWidgetLoaded) {
      return;
    }

    if (window?.zE?.widget === WidgetVersion.Messenger) {
      window.zE(`${WidgetVersion.Messenger}:on`, 'open', () => {
        setIsWidgetOpen(true);
      });

      window.zE(`${WidgetVersion.Messenger}:on`, 'close', () => {
        setIsWidgetOpen(false);
      });
    }
    setWidgetVersion(window?.zE?.widget);
  }, [window?.zE]);

  const openSupportPage = () => {
    return window.open('/contact-us', '_blank');
  };

  const handleShowWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'show');
    }
  };

  const handleOpenWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'open');
    }
  };

  const handleCloseWidget = () => {
    if (isWidgetLoaded) {
      window.zE(WidgetVersion.Messenger, 'close');
    }
  };

  if (widgetVersion === WidgetVersion.Classic) {
    // Only return the classic widget methods if the classic widget is detected
    return {
      showZendesk,
      openSupportPage,
      widgetVersion,
      ...zendeskClassicProps,
    };
  }

  if (widgetVersion === WidgetVersion.Messenger) {
    return {
      showZendesk,
      openSupportPage,
      widgetVersion,
      isWidgetOpen,
      isWidgetLoaded,
      handleOpenWidget,
      handleShowWidget,
      handleCloseWidget,
      // Stub out deprecated methods if classic widget has not been detected
      setCustomerInfo: () => {},
      setDepartment: () => {},
    };
  }

  // Return empty stubs if neither widget is detected
  return {
    showZendesk,
    openSupportPage,
    widgetVersion,
    isWidgetOpen,
    isWidgetLoaded: () => {},
    handleOpenWidget: () => {},
    handleShowWidget: () => {},
    handleCloseWidget: () => {},
    setCustomerInfo: () => {},
    setDepartment: () => {},
  };
};

export default useZendesk;
