import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AddMaterialEvaluationsDocument,
  AddMaterialEvaluationsMutation,
  AddMaterialEvaluationsMutationVariables,
  GetMaterialUploadDataDocument,
  GetMaterialUploadDataQuery,
  GetMaterialUploadDataQueryVariables,
  Material,
  MaterialEvaluationTypes,
  TreatmentPlanStaging,
  TreatmentPlanStagingStates,
} from 'generated/core/graphql';

import { Loading } from '@candidco/enamel';
import { useIsLoading } from 'state/system';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index.js';
import VersionPills, { useVersionPills } from 'components/StyledVersionPills';
import { RejectionDetailProblem } from 'pages/OrthoPrism/Plan/utils';

import * as S from 'pages/OrthoPrism/Plan/TreatmentPlan.css';

import {
  fetchCases,
  fetchCustomer,
  fetchTreatmentPlanStagings,
  selectCustomer,
  selectPlanRejectionReasons,
  selectSelectedCase,
  selectTreatmentPlanStagings,
  SubmissionFragment,
} from 'pages/OrthoPrism/orthoSlice';
import {
  MaterialReviewFormContainer,
  MaterialReviewFormItem,
  StatusBar,
} from 'pages/OrthoPrism/OrthoPrism.css';
import {
  hidePlanPermissionConditions,
  openVisionSoftware,
} from 'utils/treatmentPlan';
import { getSubmissionsFromMaterials } from 'pages/Case/TreatmentPlan/PlanDetailsContainer';
import TreatmentReviewForm from 'pages/OrthoPrism/Plan/TreatmentReviewForm';
import { useLocation } from 'react-router-dom';
import * as TreatmentPlanStagings from 'pages/OrthoPrism/treatmentPlanStagings';
import MaterialSubmissionHistory from 'components/MaterialSubmissionHistory';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import * as Sentry from '@sentry/browser';
import {
  getAwsFileLocations,
  getFormattedFileNamesFromProblems,
  getMaterialEvaluationInput,
} from 'pages/OrthoPrism/Plan/utils';
import {
  Statuses,
  TreatmentPlanInfo,
} from 'pages/OrthoPrism/Plan/TreatmentPlanStagingComponents';

const TreatmentPlanStagingSection = () => {
  const {
    'vision-dfa': visionDFAIsEnabled,
    'bypass-ortho-review-process': bypassOrthoReviewProcess,
  } = useFlags();
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  const dispatch = useDispatch();
  const isFetchingCustomerInfo = useIsLoading(fetchCustomer.type);
  const isFetchingTreatmentPlans = useIsLoading(
    fetchTreatmentPlanStagings.typePrefix
  );
  const customer = useSelector(selectCustomer);
  const selectedCase = useSelector(selectSelectedCase);
  const treatmentPlanStagings = useSelector(selectTreatmentPlanStagings);
  const rejectionReasons = useSelector(selectPlanRejectionReasons);
  const materials = (treatmentPlanStagings || []) as TreatmentPlanStaging[];

  // Not using materials directly to be consistent with how we handle materials
  // this should make this component and features easier to reuse with other
  // core material components and workflows.
  const submissions = useMemo(
    () => getSubmissionsFromMaterials(materials as Material[]),
    [materials]
  );
  const vpProps = useVersionPills(submissions, true);
  const { checkHasAccess } = useContext(AuthContext);

  const selectedPlan = treatmentPlanStagings[vpProps.currentIndex];

  const isLoading = isFetchingTreatmentPlans || !customer;
  const hasOrthoPermissions = checkHasAccess(ACCESS_GROUPS.ORTHO);
  const hasTpQcPermissions = checkHasAccess(ACCESS_GROUPS.QUALITY_CONTROL);
  const activePlan = selectedPlan ?? treatmentPlanStagings[0];
  const displayTechnicianNotes =
    bypassOrthoReviewProcess ||
    hasOrthoPermissions ||
    activePlan?.data?.bypassOrthoReview;
  const created = activePlan?.createdAt ?? null;

  const [addMaterialEvaluations, { loading }] = useGQLMutation<
    AddMaterialEvaluationsMutation,
    AddMaterialEvaluationsMutationVariables
  >(AddMaterialEvaluationsDocument, true);
  const [getTreatmentPlanStagingUploadUrl] = useGQLQuery<
    GetMaterialUploadDataQuery,
    GetMaterialUploadDataQueryVariables
  >(GetMaterialUploadDataDocument, true);

  const getMaterialEvaluationType = () => {
    if (hasTpQcPermissions && needsQcReview) {
      return MaterialEvaluationTypes.QualityControlEvaluation;
    } else if (hasOrthoPermissions && needsOrthoReview) {
      return MaterialEvaluationTypes.OrthodonticEvaluation;
    }
  };

  const getRejectionReasons = (
    approved: boolean,
    problems: RejectionDetailProblem[],
    reasons: string[] | undefined
  ) => {
    let rejectionReasons: string[] = [];
    if (!approved) {
      problems.forEach((p: RejectionDetailProblem) =>
        rejectionReasons.push(p.topic?.name ?? '')
      );
    }
    if (!approved && isOrtho) {
      rejectionReasons = [...(reasons || [])];
    }
    return rejectionReasons;
  };

  const handleUploadMaterialEvaluations = async (
    args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs
  ) => {
    const materialEvaluationType = getMaterialEvaluationType();
    if (!activePlan || !customer) {
      Sentry.captureException(
        `Missing active plan: ${activePlan} or customer: ${customer}, can't submit TreatmentPlanReview`
      );
      return;
    }
    if (materialEvaluationType === undefined) {
      return;
    }
    const formattedFiles = getFormattedFileNamesFromProblems(args.problems);
    // upload files to s3
    const awsFileLocations = await getAwsFileLocations(
      formattedFiles,
      getTreatmentPlanStagingUploadUrl,
      customer.id
    );
    const approved =
      args.transition.includes('approve') || args.transition.includes('accept');
    const rejectionReasons = getRejectionReasons(
      approved,
      args.problems,
      args.reasons
    );
    const materialEvaluationsInput = getMaterialEvaluationInput(
      args.problems,
      args.notes,
      materialEvaluationType,
      approved,
      rejectionReasons,
      activePlan.id
    );
    const materialEvaluation: AddMaterialEvaluationsMutationVariables = {
      caseRef: selectedCase?.caseRef!,
      patientId: parseInt(customer.id),
      annotatedFileLocations: awsFileLocations.map((f) => f.awsFileLocation),
      materialEvaluationsInput: materialEvaluationsInput,
    };
    try {
      await addMaterialEvaluations(materialEvaluation);
    } catch (err) {
      Sentry.captureException(err);
    }

    dispatch(fetchTreatmentPlanStagings({ caseRef: selectedCase?.caseRef! }));
    dispatch(fetchCases({ patientIds: [Number(customer?.id)] }));
  };
  const onSubmitTreatmentPlanReview = useCallback(
    (args: TreatmentPlanStagings.TreatmentPlanStagingSubmitCallbackArgs) => {
      handleUploadMaterialEvaluations(args);
    },
    [dispatch, activePlan, customer, handleUploadMaterialEvaluations]
  );
  const isApproved = TreatmentPlanStagings.isApproved(
    activePlan as TreatmentPlanStaging
  );
  const isRejected = TreatmentPlanStagings.isRejected(
    activePlan as TreatmentPlanStaging
  );
  const isQcRejected = TreatmentPlanStagings.isQcRejected(
    activePlan as TreatmentPlanStaging
  );
  const acceptedByQc: boolean =
    activePlan &&
    activePlan.state === TreatmentPlanStagingStates.OrthoReview &&
    !!activePlan?.materialEvaluations.find(
      (h) =>
        h?.evaluationType.name ===
        MaterialEvaluationTypes.QualityControlEvaluation
    );

  const needsQcReview =
    activePlan && activePlan.state === TreatmentPlanStagingStates.QcReview;
  const needsOrthoReview =
    activePlan && activePlan.state === TreatmentPlanStagingStates.OrthoReview;

  const { pathname } = useLocation();
  const isQc: boolean = useMemo(
    () => pathname.includes('tp-quality-control'),
    [pathname]
  );

  const isOrtho: boolean = useMemo(
    () => pathname.includes('ortho-prism'),
    [pathname]
  );

  const needsReview = (isQc && needsQcReview) || (isOrtho && needsOrthoReview);

  const hideTreatmentPlan: boolean =
    activePlan && hidePlanPermissionConditions.some((condition) => condition());
  if (isLoading) {
    return (
      <>
        <Loading isCentered />
        <S.CenterText>Rendering treatment plan</S.CenterText>
      </>
    );
  }

  if (!treatmentPlanStagings.length || hideTreatmentPlan) {
    return <StatusBar isPending>Treatment plan in progress</StatusBar>;
  }

  const technicianNotes = activePlan?.data?.notes;
  const hasNotes = !!technicianNotes;

  return (
    <S.Wrapper>
      <VersionPills {...vpProps} />
      <TreatmentPlanInfo
        created={created}
        activePlan={activePlan as TreatmentPlanStaging}
      />
      <S.PlanViewer>
        <S.PlanViewerContent>
          {activePlan?.url && <S.IFrame scrolling="no" src={activePlan.url} />}
        </S.PlanViewerContent>
      </S.PlanViewer>
      <MaterialReviewFormContainer
        container
        direction="column"
        alignItems="center"
        spacing={0}
      >
        <MaterialReviewFormItem item xs={12}>
          <S.BorderedDiv>
            <Statuses
              isApproved={isApproved}
              isRejected={isRejected}
              isQcRejected={isQcRejected}
              acceptedByQc={acceptedByQc}
            />

            {hasNotes && displayTechnicianNotes && (
              <S.Notes>
                <h3>Technician notes</h3>
                <p>{technicianNotes}</p>
              </S.Notes>
            )}

            {needsReview && selectedCase?.isActive && (
              <>
                {((hasTpQcPermissions && needsQcReview) ||
                  (hasOrthoPermissions && needsOrthoReview)) && (
                  <TreatmentReviewForm
                    rejectionReasons={rejectionReasons}
                    isSubmitting={isFetchingCustomerInfo || loading}
                    onSubmitTreatmentPlanStaging={onSubmitTreatmentPlanReview}
                    onClickLaunchDFAViewer={() => {
                      if (customer?.id) {
                        openVisionSoftware(
                          customer?.id,
                          selectedPlan.iteration
                        );
                      }
                    }}
                    dfaViewerButtonVisible={
                      !isMac &&
                      visionDFAIsEnabled &&
                      customer?.id &&
                      hasOrthoPermissions &&
                      needsOrthoReview &&
                      !(hasTpQcPermissions || needsQcReview)
                    }
                  />
                )}
              </>
            )}

            <MaterialSubmissionHistory
              submissions={submissions as SubmissionFragment[]}
              materialName="Treatment plan"
            />
          </S.BorderedDiv>
        </MaterialReviewFormItem>
      </MaterialReviewFormContainer>
    </S.Wrapper>
  );
};

export default TreatmentPlanStagingSection;
