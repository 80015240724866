import {
  GetLoyaltyProgramsDocument,
  GetLoyaltyProgramsQuery,
  EnrollPracticeToLoyaltyProgramMutation,
  EnrollPracticeToLoyaltyProgramMutationVariables,
  EnrollPracticeToLoyaltyProgramDocument,
  GetLoyaltyProgramsQueryVariables,
} from 'generated/core/graphql';
import { useGQLMutation, useGQLQuery } from 'hooks/useGQL';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import ReactSelect from 'react-select';
import { Button } from '@candidco/enamel';
import {
  getSelectedPractice,
  setSelectedPracticeLoyaltyStatus,
} from 'pages/PracticeManagement/shared/slice';
import { AuthContext } from 'components/AuthProvider';
import { ACCESS_GROUPS } from 'constants/index';

type LoyaltySelectOption = {
  label: string;
  value: any;
};

const Flex = styled.div<{ direction?: 'row' | 'column' }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ direction }) => direction ?? 'column'};
`;

const Container = styled(Flex)`
  width: 50vw;
  max-width: 450px;
  margin: 0 auto;
  > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const StyledButton = styled(Button)`
  width: 8em;
`;

const LoyaltySelect = styled(ReactSelect)<{
  hasError?: boolean;
  width?: number;
}>`
  min-width: 200px;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  width: ${({ width }) => (width ? `${width}px` : '200px')};
`;

const convertToOptions = (loyaltyProgram: any): LoyaltySelectOption => ({
  label: loyaltyProgram.name,
  value: loyaltyProgram,
});

const EnrollInLoyaltyForm = () => {
  const dispatch = useDispatch();
  const { checkHasAccess } = useContext(AuthContext);
  const disabled = !checkHasAccess(ACCESS_GROUPS.AUTOMATED_BILLING_ADMIN);
  const selectedPractice = useSelector(getSelectedPractice);
  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] =
    useState<any>(null);
  const [selectedLoyaltyProgramTier, setSelectedLoyaltyProgramTier] =
    useState<any>(null);
  const [getLoyaltyProgramData, { data: getLoyaltyProgramResult }] =
    useGQLQuery<GetLoyaltyProgramsQuery, GetLoyaltyProgramsQueryVariables>(
      GetLoyaltyProgramsDocument
    );
  const [submitLoyaltyEnrollment, { loading: isUpdating, data: updateResult }] =
    useGQLMutation<
      EnrollPracticeToLoyaltyProgramMutation,
      EnrollPracticeToLoyaltyProgramMutationVariables
    >(EnrollPracticeToLoyaltyProgramDocument);
  const loyaltyProgramsResult =
    getLoyaltyProgramResult?.getLoyaltyPrograms ?? [];
  const selectedLoyaltyProgramTiers =
    selectedLoyaltyProgram?.value.loyaltyProgramTiers ?? [];
  const loyaltyProgramOptions = useMemo(
    () => (!disabled ? loyaltyProgramsResult.map(convertToOptions) : []),
    [loyaltyProgramsResult]
  );
  const loyaltyProgramTierOptions = useMemo(
    () => (!disabled ? selectedLoyaltyProgramTiers.map(convertToOptions) : []),
    [selectedLoyaltyProgramTiers]
  );

  useEffect(() => {
    getLoyaltyProgramData({});
  }, []);

  useEffect(() => {
    if (updateResult?.addPracticeToLoyaltyProgram?.practiceLoyaltyStatus?.id) {
      dispatch(
        setSelectedPracticeLoyaltyStatus({
          getCurrentPracticeLoyaltyProgram:
            updateResult?.addPracticeToLoyaltyProgram?.practiceLoyaltyStatus,
        })
      );
    }
  }, [updateResult]);

  const handleSubmit = () => {
    const practiceId =
      parseInt(selectedPractice?.id as string) ?? selectedPractice?.id;
    submitLoyaltyEnrollment({
      practiceId,
      loyaltyProgramId: selectedLoyaltyProgram?.value?.id,
      startingTierId: selectedLoyaltyProgramTier?.value?.id,
    });
    setSelectedLoyaltyProgram(null);
    setSelectedLoyaltyProgramTier(null);
  };

  return (
    <Container>
      <Flex>
        {selectedLoyaltyProgram && (
          <>
            <small>Selected Loyalty Program:</small>
            <div>{selectedLoyaltyProgram.value?.name}</div>
          </>
        )}
        {selectedLoyaltyProgramTier && (
          <>
            <small>Selected Tier:</small>
            <div>{selectedLoyaltyProgramTier?.value.name}</div>
          </>
        )}
      </Flex>
      <h3>Loyalty Enrollment Form</h3>
      <Flex direction="row">
        <Flex direction="column">
          <small>Program Selection</small>
          <LoyaltySelect
            options={loyaltyProgramOptions}
            onChange={(selection: LoyaltySelectOption) => {
              setSelectedLoyaltyProgram(selection);
              setSelectedLoyaltyProgramTier(null);
            }}
            value={selectedLoyaltyProgram}
            isDisabled={disabled}
          />
        </Flex>
        <Flex direction="column">
          <small>Tier Selection</small>
          <LoyaltySelect
            options={loyaltyProgramTierOptions}
            onChange={(selection: LoyaltySelectOption) =>
              setSelectedLoyaltyProgramTier(selection)
            }
            value={selectedLoyaltyProgramTier}
            isDisabled={disabled}
          />
        </Flex>
      </Flex>
      <StyledButton
        onClick={handleSubmit}
        buttonType="primary"
        disabled={
          !selectedLoyaltyProgram || !selectedLoyaltyProgramTier || disabled
        }
        isLoading={isUpdating}
      >
        Submit
      </StyledButton>
    </Container>
  );
};

export default EnrollInLoyaltyForm;
