import { AlertCard } from '@candidco/enamel';
import React from 'react';
import styled from 'styled-components/macro';

const Header = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.default};
  padding-bottom: 0.5rem;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const QuestionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const Container = styled.div`
  margin-bottom: 2rem;
`;

const ReopenCase = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.black90};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black90};
    text-decoration: underline;
  }
`;
type Props = {
  reason: string | null | undefined;
  notes: string | null | undefined;
  caseRef: string;
  isLatestCase: boolean;
  reopenCase: () => void;
};

const CaseCanceledDataAlertCard = ({
  reason,
  notes,
  reopenCase,
  isLatestCase,
}: Props) => {
  return (
    <Container>
      <AlertCard displayIcon={false} type="default">
        <Header>{reason}</Header>
        <QuestionText>{notes}</QuestionText>
        {isLatestCase && (
          <ReopenCase
            onClick={() => {
              reopenCase();
            }}
          >
            Re-open case
          </ReopenCase>
        )}
      </AlertCard>
    </Container>
  );
};

export default CaseCanceledDataAlertCard;
