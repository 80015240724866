import React, { useContext } from 'react';
import { Button, theme } from '@candidco/enamel';
import styled from 'styled-components/macro';
import { AuthContext } from 'components/AuthProvider';

const Banner = styled.div`
  background-color: ${theme.colors.blue70};
  display: flex;
  flex-direction: row;
  flex-flow: nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: ${theme.colors.white};
  height: 56px;
  padding: 12px 32px;
`;

const BannerActionButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: normal;
  flex-shrink: 0;
`;

const ImpersonationBanner = () => {
  const { handleLogOut, impersonatorId } = useContext(AuthContext);

  if (!impersonatorId) {
    return null;
  }

  return (
    <Banner>
      <div>
        <b>{`Warning: `}</b>
        {`You’re using Pin Login to access this account. Actions made during your session will apply to the provider’s actual account.`}
      </div>
      <BannerActionButton buttonType="secondary" onClick={handleLogOut}>
        Log out
      </BannerActionButton>
    </Banner>
  );
};

export default ImpersonationBanner;
