import { StatusBar } from 'pages/OrthoPrism/OrthoPrism.css';
import React from 'react';
import * as S from 'pages/OrthoPrism/Plan/TreatmentPlan.css';
import {
  getTreatmentPlanStagingViewerUrl,
  toCreatedString,
} from 'utils/treatmentPlan';
import CopyableText from 'components/CopyableText';
import { TreatmentPlanStaging } from 'generated/core/graphql';

type StatusesType = {
  isApproved: boolean;
  isRejected: boolean;
  isQcRejected: boolean;
  acceptedByQc: boolean;
};
export const Statuses = ({
  isApproved,
  isRejected,
  isQcRejected,
  acceptedByQc,
}: StatusesType) => (
  <>
    <>
      {isApproved && <StatusBar isApproved>Treatment plan accepted</StatusBar>}
      {isRejected && (
        <StatusBar isRejected>Treatment plan revision requested</StatusBar>
      )}
      {isQcRejected && (
        <StatusBar isRejected>Rejected by Quality Control</StatusBar>
      )}
      {acceptedByQc && (
        <StatusBar isPending>
          Accepted by Quality Control, waiting for ortho review
        </StatusBar>
      )}
    </>
  </>
);

type TreatmentPlanInfoType = {
  created: string | null;
  activePlan: TreatmentPlanStaging;
};
export const TreatmentPlanInfo = ({
  created,
  activePlan,
}: TreatmentPlanInfoType) => (
  <S.InfoBlocks>
    <li>
      <S.Overline>CREATED</S.Overline>
      <div>{toCreatedString(created)}</div>
    </li>
    <li
      style={{
        display: 'inline-flex',
        alignItems: 'end',
      }}
    >
      Share Plan
      <CopyableText
        text={getTreatmentPlanStagingViewerUrl(
          activePlan as TreatmentPlanStaging
        )}
        label="Treatment Plan Viewer"
      />
    </li>
  </S.InfoBlocks>
);
