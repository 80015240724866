import CandidLogo from 'assets/candid-pro-no-circle-no-tm-red.svg';
import GlidewellLogo from 'assets/glidewell-logo.svg';
import LaunchNotesBackground from 'assets/launch-notes-background.png';
import GlidewellLoginRightPanel from 'assets/GlidewellLoginRightPanel.jpg';
import GlidewellFavicon from 'assets/favicon-glidewell.ico';
import CandidFavicon from 'assets/favicon-candid.ico';
import refinementImgSrc from 'assets/orderItems/refinement.png';
import alignerCandidProImgSrc from 'assets/orderItems/aligner-candidpro.png';
import alignerPromonitoringImgSrc from 'assets/orderItems/aligner-promonitoring.png';
import retainerImgSrc from 'assets/orderItems/retainer.png';
import RetainerCandidPng from 'assets/products/candid/retainers.png';
import RetainerGlidewellPng from 'assets/products/glidewell/retainers.png';

// Eventually these will be deprecated and replaced when the Brand Service API is live (pushed to version 2 of the white labellling project)
// For now this will serve as a temporary solution to have Brand information available pre-authentication and should be kept in sync the Brands table
export const CANDID_BRAND_ID = 1;
export const CANDID_BRAND_LABEL = 'CandidPro';
export const CANDID_BRAND_NAME = 'candid_pro';
const CANDID_SUPPORT_EMAIL = 'doctor@candidco.com';
const CANDID_MARKETING_EMAIL = 'marketing@candidco.com';

const GLIDEWELL_BRAND_ID = 2;
export const GLIDEWELL_BRAND_LABEL = 'Glidewell';
export const GLIDEWELL_BRAND_NAME = 'glidewell';
const GLIDEWELL_SUPPORT_EMAIL = 'doctor@promonitoring.ai';
export const GLIDEWELL_URL = 'glidewell.promonitoring.ai';
const GLIDEWELL_MARKETING_EMAIL = 'marketing@promonitoring.ai';

const BRAND_LOGO_MAP: Record<string, string> = {
  [CANDID_BRAND_NAME]: CandidLogo,
  [GLIDEWELL_BRAND_NAME]: GlidewellLogo,
};

const BRAND_FAVICON_MAP: Record<string, string> = {
  [CANDID_BRAND_NAME]: CandidFavicon,
  [GLIDEWELL_BRAND_NAME]: GlidewellFavicon,
};

export const BRAND_ORTHO_BIOS: Record<string, Record<string, string>> = {
  [CANDID_BRAND_NAME]: {
    'ed@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9FNCXTXK51G15APSSQVW',
    'dr.michael.kabot@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9N28F1PC6XA1PTBPQXV3',
    'dr.perry.stevenson@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H43T9WQN3SY4VZ6ZZC7TVY4K',
    'dr.ammar.siddiqi@candidco.com':
      'https://candidpro.zendesk.com/hc/en-us/articles/7548443118871-Treatment-Plans-Notes-Revisions-Scheduling-a-Call-with-an-Orthodontist#h_01H7JWA8JQ9DKN0GYKM6KFTGBM',
  },
  [GLIDEWELL_BRAND_NAME]: {
    'ed@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTCZBVYDH75G2H9H7C6XD59',
    'dr.michael.kabot@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTCZKT10XP53NKD3TW52JBQ',
    'dr.perry.stevenson@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTD02E3962NHX5JZN8RG47G',
    'dr.ammar.siddiqi@candidco.com':
      'https://promonitoring.zendesk.com/hc/en-us/articles/23185054845719-Orthodontist-Biographies#h_01HWTD065AM95XQJ320SQD13J7',
  },
};

//////////////////////////
// Brand Domains Pages Settings
// These settings are purely aesthetic and are used to customize the look and feel of the pages based on the brand
//////////////////////////
export const BRAND_DOMAIN_SETTINGS: Record<
  string,
  Record<string, string | number | boolean>
> = {
  [CANDID_BRAND_NAME]: {
    brandId: CANDID_BRAND_ID,
    loginBackgroundImgSrc: LaunchNotesBackground,
    label: CANDID_BRAND_LABEL,
    name: CANDID_BRAND_NAME,
    logoSrc: BRAND_LOGO_MAP[CANDID_BRAND_NAME],
    favicon: BRAND_FAVICON_MAP[CANDID_BRAND_NAME],
    supportEmail: CANDID_SUPPORT_EMAIL,
    marketingEmail: CANDID_MARKETING_EMAIL,
    monitoringLabel: 'CandidMonitoring',
    showLaunchNotes: true,
    showLoyaltyInfo: true,
    showZendesk: true,
    helpCenterUrl: 'https://candidpro.zendesk.com/hc/en-us',
  },
  [GLIDEWELL_BRAND_NAME]: {
    brandId: GLIDEWELL_BRAND_ID,
    loginBackgroundImgSrc: GlidewellLoginRightPanel,
    label: GLIDEWELL_BRAND_LABEL,
    name: GLIDEWELL_BRAND_NAME,
    logoSrc: BRAND_LOGO_MAP[GLIDEWELL_BRAND_NAME],
    favicon: BRAND_FAVICON_MAP[GLIDEWELL_BRAND_NAME],
    subheader: 'Log into the provider portal',
    supportEmail: GLIDEWELL_SUPPORT_EMAIL,
    marketingEmail: GLIDEWELL_MARKETING_EMAIL,
    monitoringLabel: 'ProMonitoring',
    showLaunchNotes: false,
    showLoyaltyInfo: false,
    showZendesk: false,
    helpCenterUrl: 'https://promonitoring.zendesk.com/hc/en-us',
  },
};

//////////////////////////
// Brand Supported Features
// These are the functional feature that are supported by the brand of the user or viewing customer
//////////////////////////
export const BRAND_SUPPORTED_FEATURES: Record<
  string,
  Record<string, boolean>
> = {
  [CANDID_BRAND_NAME]: {
    PvsScanFlow: true,
    DisplayPricesToProviders: true,
  },
  [GLIDEWELL_BRAND_NAME]: {
    PvsScanFlow: false,
    DisplayPricesToProviders: false,
  },
};

export const DEFAULT_LINKS = {
  PHOTO_EXPECTATIONS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7440183329687-CandidPro-Diagnostic-Photo-and-Scan-Guide',
  SCANS_EXPORT_STL:
    'https://candidpro.zendesk.com/hc/en-us/articles/9191905370647-Top-Scanner-Instructions-Resources',
  SCANS_UNZIP_EXPORTED_SCANS:
    'https://candidpro.zendesk.com/hc/en-us/articles/10732444716439',
  SCANS_LAB_REQUIREMENTS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7542528616471-CandidPro-Diagnostic-Impression-Guide',
  TREATMENT_GOALS_PROTOCOLS:
    'https://candidpro.zendesk.com/hc/en-us/articles/7542873494295',
};

//////////////////////////
// Brand Checkout SKUs
// These are the SKUs used to create the products in the checkout flow, these SKU will live here until the Order Revamp project is complete
//////////////////////////

type CheckoutSkusInfo = {
  sku: string;
  description: string;
  price: number;
  imageSrc: string;
  providerFacingProductName: string;
};

type CheckoutSkus = {
  lastStepAligner: CheckoutSkusInfo;
  lastStepRetainer: CheckoutSkusInfo;
  retainer: CheckoutSkusInfo;
  refinement: CheckoutSkusInfo;
  aligner: CheckoutSkusInfo;
  replacement: CheckoutSkusInfo;
};

interface BrandToSkuType {
  [key: string]: CheckoutSkus;
}

export const SKUs: BrandToSkuType = {
  candid_pro: {
    lastStepAligner: {
      sku: 'RT00001',
      description: 'Last Step Aligner',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step aligner',
    },
    lastStepRetainer: {
      sku: 'RET-REORDER',
      description: 'Last Step Retainer',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step retainer',
    },
    retainer: {
      sku: 'RET-NEW',
      description: 'Retainer',
      price: 9900,
      imageSrc: RetainerCandidPng,
      providerFacingProductName: 'Retainer',
    },
    refinement: {
      sku: 'REFINE0003',
      description:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      price: 40000,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    aligner: {
      sku: 'AK00007',
      description:
        'CandidPro’s proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.',
      price: 190000,
      imageSrc: alignerCandidProImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    replacement: {
      sku: 'AKREPL0001',
      description:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      price: 4950,
      imageSrc: '', //Not used, and needs to be refactored to usue common photo
      providerFacingProductName: 'Replacement aligners',
    },
  },
  glidewell: {
    lastStepAligner: {
      sku: 'PERMAFORM',
      description: 'Last Step Aligner',
      price: 9900,
      imageSrc: '',
      providerFacingProductName: 'Last step aligner',
    },
    lastStepRetainer: {
      sku: 'PERMAFORM-REORDER',
      description: 'Last Step Retainer',
      price: 9900,
      imageSrc: retainerImgSrc,
      providerFacingProductName: 'Last step retainer',
    },
    retainer: {
      sku: 'PERMAFORM-NEW',
      description: 'Retainer',
      price: 9900,
      imageSrc: RetainerGlidewellPng,
      providerFacingProductName: 'Retainer',
    },
    refinement: {
      sku: 'GL-REFINE02',
      description:
        'Start another round of aligners to ensure a perfectly aligned smile. Our team will determine how many more trays are needed.',
      price: 40000,
      imageSrc: refinementImgSrc,
      providerFacingProductName: 'Refinements',
    },
    aligner: {
      sku: 'GL-ALIGNER-KIT',
      description:
        'Promonitoring’s proprietary down-to-the-micron manufacturing process creates some of the best clear aligners in the industry.',
      price: 99900,
      imageSrc: alignerPromonitoringImgSrc,
      providerFacingProductName: 'Comprehensive aligners',
    },
    replacement: {
      sku: 'GL-AKREPL0001',
      description:
        'If a patient misplaced one or more aligner trays, you can easily order replacements.',
      price: 4950,
      imageSrc: '', //Not used, and needs to be refactored to usue common photo
      providerFacingProductName: 'Replacement aligners',
    },
  },
};
