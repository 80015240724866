import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { hideVisually } from 'polished';

import {
  setCurrentTabId,
  selectCurrentTabId,
  selectCustomer,
  fetchXrays,
  fetchPrismAggregates,
  fetchCustomer,
  fetchScans,
  selectSelectedCaseState,
  CaseState,
  fetchCases,
} from 'pages/OrthoPrism/orthoSlice';
import Compare, {
  TabLabel,
  TabLabelType,
  DiagnosticMaterialTab,
} from 'components/Compare';
import Photos from 'pages/OrthoPrism/Photos';
import Xrays from 'pages/OrthoPrism/Xrays';
import Scans from 'pages/OrthoPrism/Scans';
import Intake from 'pages/OrthoPrism/Intake';

import MaterialStateChip from 'components/MaterialStateChip/MaterialStateChip';
import TreatmentPlanStagingSection from 'pages/OrthoPrism/Plan/TreatmentPlanStagingSection';

import { MaterialState } from 'generated/core/graphql';

const Wrapper = styled.div`
  min-height: 50rem;
`;

const TabContainer = styled.nav`
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;

const Tab = styled.button<{
  isDisabled?: boolean;
  isActive?: boolean;
  isHidden?: boolean;
}>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'inline-flex')};
  justify-content: space-around;
  align-items: center;
  padding: 1.25rem 1.5rem 1rem;
  border-bottom: 3px solid white;
  border-color: none;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.text10 : theme.colors.black70};
  ${({ isActive, theme }) =>
    isActive &&
    css`
      color: ${theme.colors.blue50};
      border-color: ${theme.colors.blue50};
    `};

  *:nth-child(3) {
    padding-left: 0.5rem;
  }
`;

const CompareBar = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1rem;
`;

const TabContent = styled.div<{ isVisible?: boolean }>`
  ${({ isVisible }) => !isVisible && hideVisually()};
`;

const MaybeSideBySide = styled.div`
  display: flex;
  flex-direction: row;
`;
const LeftPanel = styled.div`
  flex: 1;
`;
const RightPanel = styled.div`
  flex: 1;
  padding-left: 2rem;
`;

const TabLabelContainer = styled.span`
  padding-top: 0.25rem;
  margin-right: 0.5rem;
`;

const isTabId = (tabId: any): tabId is TabLabelType =>
  Object.values(TabLabel).includes(tabId);

const allTabs: DiagnosticMaterialTab[] = [
  {
    name: TabLabel.Photos,
    Component: Photos,
    isDisabled: false,
    caseStateKey: 'photos',
  },
  {
    name: TabLabel.Xrays,
    Component: Xrays,
    isDisabled: false,
    caseStateKey: 'xrays',
  },
  {
    name: TabLabel.Scans,
    Component: Scans,
    isDisabled: false,
    caseStateKey: 'scans',
  },
  {
    name: TabLabel.TreatmentGoals,
    Component: Intake,
    isDisabled: false,
    caseStateKey: 'treatmentGoals',
  },
  {
    name: TabLabel.Plan,
    Component: TreatmentPlanStagingSection,
    isDisabled: false,
    caseStateKey: 'treatmentPlanStaging',
    allowCompareWithSelf: true,
  },
];

type CaseStateKey = keyof CaseState;

const materialStateKeys: CaseStateKey[] = [
  'photos',
  'xrays',
  'scans',
  'treatmentGoals',
  'treatmentPlanStaging',
];

const getTabs = (caseState: CaseState | null): DiagnosticMaterialTab[] => {
  if (!caseState) {
    return [];
  }
  return materialStateKeys
    .filter((key) => key in caseState && caseState[key as keyof CaseState])
    .map((key) => {
      const tab = allTabs.find((tab) => tab.caseStateKey === key);
      const materialState = (caseState[key] ?? {}) as MaterialState;
      const isDisabled = !materialState;

      return {
        ...tab,
        isDisabled,
      };
    }) as DiagnosticMaterialTab[];
};

const OrthoTabs = ({ isQc }: { isQc?: boolean }) => {
  const location = useLocation();
  const tabsRef = useRef<HTMLElement>(null);
  const dispatch = useDispatch();
  const currentTabId = useSelector(selectCurrentTabId);
  const [compareTab, setCompareTab] = useState<string>('');
  const customer = useSelector(selectCustomer);
  const selectedCaseState = useSelector(selectSelectedCaseState);

  //Because of 'Needs clarification` actions taken within one tab may affect any other tab
  const refreshMaterials = (
    reloadScans: boolean,
    reloadXrays: boolean,
    reloadPhotos: boolean,
    caseRef: string
  ) => {
    // fetch the updated journey state
    dispatch(
      fetchCustomer({
        customerId: customer?.id as string,
      })
    );

    dispatch(fetchCases({ patientIds: [Number(customer?.id)] }));

    if (reloadScans) {
      dispatch(
        fetchScans({
          caseRef: caseRef!,
        })
      );
    }
    if (reloadXrays) {
      dispatch(
        fetchXrays({
          caseRef: caseRef!,
        })
      );
    }
    if (reloadPhotos) {
      dispatch(
        fetchPrismAggregates({
          caseRef: caseRef!,
        })
      );
    }
  };

  const schema = useMemo(() => getTabs(selectedCaseState), [selectedCaseState]);

  const comparableTabs = schema.filter((tab) => !tab.isDisabled);

  const onCompare = useCallback(
    (tabId) => {
      setCompareTab(tabId);
    },
    [setCompareTab]
  );

  /*
     Arriving on this page with a location hash in the format
     `#scroll-to:<TabId>` will scroll the user to the top of the
     TabContainer component and select the passed tab.
   */
  useEffect(() => {
    const target = isQc ? TabLabel.Plan : location.hash.split(':')[1];
    if (isTabId(target)) {
      dispatch(setCurrentTabId(target));
      if (tabsRef.current) {
        const { offsetTop, offsetHeight } = tabsRef.current;

        window.scroll({
          behavior: 'smooth',
          top: offsetTop - offsetHeight,
        });
      }
    } else {
      // Otherwise default to the Treatment goals/plan tab
      // We can't rely on the initial state because the intake name is derived from the selected case
      dispatch(setCurrentTabId(TabLabel.TreatmentGoals));
    }
  }, [location.hash, dispatch]);

  const ContentArea = schema.map(({ name, Component }) => (
    <TabContent isVisible={name === currentTabId} key={name}>
      <Component refreshMaterials={refreshMaterials} />
    </TabContent>
  ));

  const CompareComponent = schema.find((t) => t.name === compareTab)?.Component;
  const CompareArea = CompareComponent && (
    <RightPanel>
      <CompareComponent refreshMaterials={refreshMaterials} />
    </RightPanel>
  );

  return (
    <Wrapper>
      <TabContainer ref={tabsRef}>
        {schema.map(({ name, isDisabled }: DiagnosticMaterialTab) => (
          <Tab
            key={name}
            data-testid={`ortho-tab-${name}`}
            isActive={name === (currentTabId as string)}
            isDisabled={isDisabled}
            disabled={isDisabled}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              setCompareTab('');
              dispatch(setCurrentTabId(name as TabLabelType));
            }}
          >
            <TabLabelContainer>{name}</TabLabelContainer>
            {!isDisabled && (
              <MaterialStateChip
                name={name}
                isQc={isQc}
                caseOverview={selectedCaseState?.internal}
              />
            )}
          </Tab>
        ))}
      </TabContainer>
      <CompareBar>
        <Compare
          compareTab={compareTab}
          currentTab={currentTabId}
          onCompare={onCompare}
          comparableTabs={comparableTabs}
        />
      </CompareBar>

      <MaybeSideBySide>
        <LeftPanel>{ContentArea}</LeftPanel>
        {CompareArea}
      </MaybeSideBySide>
    </Wrapper>
  );
};

export default OrthoTabs;
