import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Grid, Button, NotificationContext, theme } from '@candidco/enamel';
import { Popover, useMediaQuery } from '@material-ui/core';
import { captureException } from '@sentry/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CaseSource,
  SendDmActivationEmailMutation,
  SendDmActivationEmailMutationVariables,
  SendDmActivationEmailDocument,
  StartMonitoringPatientMutation,
  StartMonitoringPatientMutationVariables,
  StartMonitoringPatientDocument,
} from 'generated/core/graphql';
import useZendesk from 'hooks/useZendesk';
import { useGQLMutation } from 'hooks/useGQL';
import useMarkAkAsDelivered from 'hooks/useMarkAkAsDelivered';

import { getBrandDomainSettings } from 'utils/brands';

import { PROVIDER_FACING_STATUSES, ACTIONS } from 'constants/caseStatus';
import Check from 'assets/check-white.svg?react';
import PlusIcon from 'assets/ic_plus.svg?react';
import PlayIcon from 'assets/play.svg?react';

import { availableActionsForProviderFacingStatus } from 'utils/case';

import { useAuthContext } from 'components/AuthProvider';
import {
  ArchiveCaseModal,
  TrimmingModal,
  AdditionalAlignersModalLegacy,
  RefinementsModal,
  CompleteCaseConfirmation,
  OrderRetainersModal,
} from 'components/Modals';
import OrderShippingModal from 'components/Modals/OrderShippingModal';

import {
  AdditionalItemsMenu,
  LinkItem,
  ArrowDown,
  ActionButton,
  Label,
  Checkbox,
  MarkCompleteButton,
  ActionGroupContainer,
} from 'pages/Patient/PatientDetail/CaseStatus/CaseStatus.css';

import { JourneyTransition } from 'pages/Case/types';
import { actionConfigs } from 'pages/Patient/PatientDetail/ActionMappingConfig';

import {
  selectPatientName,
  selectPatient,
  resetPatientState,
  selectAlignerTrackingLink,
  selectLatestTreatmentPlanStaging,
  fetchCases,
  cancelCoreCase,
  setIsRefinementModalOpen,
  setIsProductSelectionModalOpen,
  selectReplacementAlignerFormLink,
  selectIsPatientElgiibleForRefinement,
} from 'pages/Patient/patientSlice';
import {
  ALIGNER_JOURNEY_TYPE,
  ALIGNER_PRODUCTION_LEG,
} from 'pages/Case/CaseProvider';
import { searchDMPatientsURL } from 'utils/url';
import { useTransitionJourneyMutation } from 'generated/legacy/graphql';
import {
  PopoverActionProps,
  ActionModalsProps,
  ActionOnClickConfig,
} from 'pages/Patient/types';
import RefinementButton from 'pages/Patient/PatientDetail/CaseStatus/RefinementButton';
import { selectAppliedRefinementDiscountType } from 'pages/Promotion/promotionsSlice';
const ActionsModals = ({
  archiveCaseModalProps,
  trimmingModalProps,
  additionalAlignersModalProps,
  orderShippingModalProps,
  isCompleteCaseModalProps,
  orderRetainersModalProps,
}: ActionModalsProps) => {
  const { 'enable-refinement-policy': enableRefinementPolicy } = useFlags();
  const AdditionalAlignersModal = enableRefinementPolicy
    ? RefinementsModal
    : AdditionalAlignersModalLegacy;

  return (
    <>
      <ArchiveCaseModal {...archiveCaseModalProps} />
      <TrimmingModal {...trimmingModalProps} />
      <AdditionalAlignersModal {...additionalAlignersModalProps} />
      <OrderShippingModal {...orderShippingModalProps} />
      <CompleteCaseConfirmation {...isCompleteCaseModalProps} />
      <OrderRetainersModal {...orderRetainersModalProps} />
    </>
  );
};

const Actions = ({
  providerFacingStatus,
  selectedCase,
  isLoading,
}: PopoverActionProps) => {
  const { showNotification } = useContext(NotificationContext);
  const { monitoringLabel } = getBrandDomainSettings();
  const {
    'white-labeling-brand-support': whiteLabeling,
    'enable-monitoring-account-preferences': enableMonitoringAccountPreferences,
    'enable-refinement-policy': enableRefinementPolicy,
  } = useFlags();
  const [sendActivationEmailFn, { loading: isSendingActionEmail }] =
    useGQLMutation<
      SendDmActivationEmailMutation,
      SendDmActivationEmailMutationVariables
    >(SendDmActivationEmailDocument, true);
  const [startMonitoringPatientFn, { loading: isStartingMonitoring }] =
    useGQLMutation<
      StartMonitoringPatientMutation,
      StartMonitoringPatientMutationVariables
    >(StartMonitoringPatientDocument, true);
  const activeTreatmentPlan = useSelector(selectLatestTreatmentPlanStaging);

  const hasRefinementCoupon = useSelector(selectAppliedRefinementDiscountType);
  const isPatientElgiibleForRefinement = useSelector(
    selectIsPatientElgiibleForRefinement
  );

  const [isArchiveCaseModalOpen, setIsArchiveCaseModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isModalAcknowledged, setIsModalAcknowledged] = useState(false);
  const [isTrimmingModalOpen, setIsTrimmingModalOpen] = useState(false);
  const [isOrderRetainersModalOpen, setIsOrderRetainersModalOpen] =
    useState(false);
  const [isOrderShippingModalOpen, setIsOrderShippingModalOpen] =
    useState(false);
  const [isAdditionalAlignersModalOpen, setIsAdditionalAlignersModalOpen] =
    useState(false);
  const formLink = useSelector(selectReplacementAlignerFormLink);
  const { markAkAsDelivered } = useMarkAkAsDelivered();
  const { push } = useHistory();
  const mobileSize = useMediaQuery(theme.mediaQueries.mobile);
  // Control popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleOpenPopOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setAnchorEl(null);
  };
  const isPopOverOpen = Boolean(anchorEl);
  const popOverId = isPopOverOpen ? 'simple-popover' : undefined;

  const patient = useSelector(selectPatient);
  const isTreatmentPlanningInProgress =
    providerFacingStatus ===
    PROVIDER_FACING_STATUSES.TREATMENT_PLANNING_IN_PROGRESS;
  const isInTreatment =
    providerFacingStatus === PROVIDER_FACING_STATUSES.IN_TREATMENT;
  const { canOrderRetainers, canSendDmActivationEmail } =
    availableActionsForProviderFacingStatus(providerFacingStatus);
  const showSendActivationEmail =
    patient?.dmGatewayId && canSendDmActivationEmail;
  const patientFullName = useSelector(selectPatientName);
  const monitoringLink = `${searchDMPatientsURL(whiteLabeling)}${encodeURIComponent(
    patient?.user?.email ?? ''
  )}`;
  const trackingLink = useSelector(selectAlignerTrackingLink);

  const {
    showZendesk,
    openSupportPage,
    isWidgetLoaded,
    setCustomerInfo,
    setDepartment,
    handleOpenWidget,
  } = useZendesk();
  const { userInfo } = useAuthContext();

  const showStartMonitoringButton =
    enableMonitoringAccountPreferences &&
    providerFacingStatus === PROVIDER_FACING_STATUSES.ALIGNER_KIT_DELIVERED;
  const filteredActions = Object.keys(actionConfigs).filter(
    (action) =>
      !(
        (action === ACTIONS.VIEW_PRINT_TREATMENT_PLAN_PDF &&
          !activeTreatmentPlan?.pdfUrl) ||
        (action == ACTIONS.TRACK_SHIPMENT && !trackingLink) ||
        (action === ACTIONS.REQUEST_REFINEMENTS && enableRefinementPolicy)
      )
  );

  useEffect(() => {
    if (userInfo) {
      setCustomerInfo({
        email: userInfo?.email ?? '',
        name: userInfo?.doctor?.fullName ?? '',
      });
    }
    setDepartment('CandidPro');
  }, [isWidgetLoaded, userInfo]);
  const requestAdditionalAlignerSideEffect = () => {
    setIsAdditionalAlignersModalOpen(false);
    dispatch(resetPatientState());
    push(`/patient/${patient?.id}/case-creator`);
  };

  const dispatch = useDispatch();

  const [transitionJourney, { loading: isTransistionJourneySubmitting }] =
    useTransitionJourneyMutation({
      onCompleted: () => {
        if (!patient) {
          return;
        }
        dispatch(fetchCases({ patientIds: [Number(patient.id)] }));
      },
    });

  const cancelCase = (archiveReason: string) => {
    if (!selectedCase || !patient) {
      return;
    }
    if (selectedCase?.source === CaseSource.CaseService) {
      transitionJourney({
        variables: {
          caseRef: selectedCase.caseRef,
          component: ALIGNER_JOURNEY_TYPE,
          transition: JourneyTransition.ForceCaseCanceled,
          transitionReason: archiveReason,
        },
      });
    } else {
      dispatch(
        cancelCoreCase({
          caseRef: selectedCase.caseRef,
          reason: archiveReason,
        })
      );
      dispatch(fetchCases({ patientIds: [Number(patient.id)] }));
    }
  };

  const completeCase = () => {
    if (selectedCase?.source === CaseSource.CaseService) {
      transitionJourney({
        variables: {
          caseRef: selectedCase?.caseRef ?? '',
          component: ALIGNER_PRODUCTION_LEG,
          transition: JourneyTransition.Complete,
        },
      });
    }
  };

  const sendActivationEmail = () => {
    sendActivationEmailFn({
      patientId: patient?.id ?? '',
    })
      .then((data) => {
        showNotification(
          `Activation email sent to ${data?.sendDmActivationEmail?.response?.to?.email}`,
          'success'
        );
      })
      .catch((error) => {
        showNotification(error?.message, 'error');
      });
  };

  const startMonitoring = () => {
    if (!patient) {
      return;
    }

    startMonitoringPatientFn({
      patientId: patient.id,
    })
      .then(() => {
        transitionJourney({
          variables: {
            caseRef: selectedCase.caseRef,
            component: ALIGNER_PRODUCTION_LEG,
            transition: JourneyTransition.ForceInTreatment,
          },
        });
        showNotification(`Monitoring started for patient`, 'success');
      })
      .catch((error) => {
        captureException(error);

        showNotification(
          error?.message || `Failed to start monitoring for patient`,
          'error'
        );
      });
  };

  const actionOnClickMap: ActionOnClickConfig = {
    [ACTIONS.ARCHIVE_CASE]: () => setIsArchiveCaseModalOpen(true),
    [ACTIONS.MARK_DELIVERED]: async () => {
      if (selectedCase?.source === CaseSource.CaseService && patient) {
        try {
          await markAkAsDelivered({
            caseRef: selectedCase?.caseRef,
            patient,
          });
        } catch (error) {
          if (error instanceof Error) {
            let errorMessage = 'Unable to mark AK as delivered.';

            if (error instanceof Error) {
              errorMessage = `${errorMessage}  ${error.message}`;
            }

            showNotification(errorMessage, 'error');
          }
        }

        dispatch(fetchCases({ patientIds: [Number(patient.id)] }));
      }
    },
    [ACTIONS.VIEW_IN_CANDID_MONITORING]: () => {
      const monitoringLink = `${searchDMPatientsURL(whiteLabeling)}${encodeURIComponent(
        patient?.user?.email ?? ''
      )}`;
      window.open(monitoringLink, '_blank');
    },
    [ACTIONS.ORDER_REPLACEMENT]: () => window.open(formLink, '_blank'),
    [ACTIONS.ORDER_RETAINERS]: () => setIsOrderRetainersModalOpen(true),
    [ACTIONS.REQUEST_REFINEMENTS]: () =>
      enableRefinementPolicy
        ? dispatch(setIsRefinementModalOpen(true))
        : setIsAdditionalAlignersModalOpen(true),
    [ACTIONS.REQUEST_ADDITIONAL_ALIGNERS]: () =>
      enableRefinementPolicy
        ? dispatch(setIsRefinementModalOpen(true))
        : setIsAdditionalAlignersModalOpen(true),
    [ACTIONS.VIEW_PRINT_TREATMENT_PLAN_PDF]: () =>
      window.open(activeTreatmentPlan?.pdfUrl ?? ''),
    [ACTIONS.TRACK_SHIPMENT]: () => window.open(trackingLink, '_blank'),
    [ACTIONS.VIEW_ORDERS_AND_SHIPPING]: () => setIsOrderShippingModalOpen(true),
    [ACTIONS.CONTACT_SUPPORT]: () => {
      if (!showZendesk) {
        return openSupportPage();
      }

      return handleOpenWidget();
    },
  };

  const tpDetail = useMemo(() => {
    if (activeTreatmentPlan?.data) {
      return activeTreatmentPlan?.data;
    }

    return null;
  }, [activeTreatmentPlan?.data]);
  const actionModalsProps = {
    archiveCaseModalProps: {
      isOpen: isArchiveCaseModalOpen,
      inPlanning: isTreatmentPlanningInProgress,
      onClose: () => {
        setIsArchiveCaseModalOpen(false);
      },
      onConfirm: (archiveReason: string) => {
        setIsArchiveCaseModalOpen(false);
        cancelCase(archiveReason);
      },
    },
    orderRetainersModalProps: {
      isOpen: isOrderRetainersModalOpen,
      caseRef: selectedCase?.caseRef,
      onClose: () => {
        setIsOrderRetainersModalOpen(false);
      },
    },
    trimmingModalProps: {
      isOpen: isTrimmingModalOpen,
      onTrimClicked: () => {
        setIsTrimmingModalOpen(false);
      },
      onNewAlignersClicked: () => {
        setIsTrimmingModalOpen(false);
        if (enableRefinementPolicy) {
          dispatch(setIsRefinementModalOpen(true));
        } else {
          setIsAdditionalAlignersModalOpen(true);
        }
      },
    },
    additionalAlignersModalProps: {
      isOpen: isAdditionalAlignersModalOpen,
      onCancelClicked: () => {
        setIsAdditionalAlignersModalOpen(false);
      },
      onSubmitted: () => requestAdditionalAlignerSideEffect(),
      treatmentPlanSteps: tpDetail?.steps,
      caseId: selectedCase?.data?.caseId,
      caseRef: selectedCase?.caseRef,
      patientId: selectedCase?.patientId,
      stateOfTreatment: selectedCase?.stateOfTreatment,
      candidMonitoringLink: monitoringLink,
      patientName: patientFullName ?? '',
      formLink: formLink,
    },
    orderShippingModalProps: {
      isOpen: isOrderShippingModalOpen,
      onClose: () => {
        setIsOrderShippingModalOpen(false);
      },
      caseRef: selectedCase?.caseRef,
      orderItems: selectedCase?.orderItems,
      incitingOrderItemRef: selectedCase?.incitingOrderItem?.orderItemRef,
      patientName: patientFullName ?? '',
      internal: false,
    },
    isCompleteCaseModalProps: {
      isOpen: isCompleteModalOpen,
      isLoading: isTransistionJourneySubmitting,
      onClose: () => {
        setIsCompleteModalOpen(false);
      },
      onConfirm: () => {
        setIsCompleteModalOpen(false);
        completeCase();
      },
      onCancel: () => {
        setIsCompleteModalOpen(false);
      },
      headerText: 'Mark case as complete',
      confirmButtonText: 'Submit',
      isConfirmDisabled: !isModalAcknowledged,
      children: (
        <>
          By marking this case as complete, I acknowledge that the patient has
          met my clinical goals for their treatment.
          <Label>
            <Checkbox
              checked={isModalAcknowledged}
              onChange={() => setIsModalAcknowledged(!isModalAcknowledged)}
              type="checkbox"
            />
            Acknowledge
          </Label>
        </>
      ),
    },
  };

  return (
    <ActionGroupContainer>
      <Grid container alignItems="center" spacing={1}>
        {isInTreatment && (
          <Grid item xs={12} sm="auto">
            <MarkCompleteButton
              isFullWidth
              buttonType="secondary"
              onClick={() => {
                setIsCompleteModalOpen(true);
              }}
            >
              <Check style={{ marginBottom: '3px', marginRight: '8px' }} /> Mark
              as complete
            </MarkCompleteButton>
          </Grid>
        )}
        {canOrderRetainers && (
          <Grid item xs={12} sm="auto">
            <Button
              isFullWidth
              buttonType="secondary"
              onClick={() => setIsOrderRetainersModalOpen(true)}
              leftIcon={<PlusIcon />}
            >
              Order retainers
            </Button>
          </Grid>
        )}
        {hasRefinementCoupon !== null &&
          isPatientElgiibleForRefinement &&
          enableRefinementPolicy && (
            <Grid item xs={12} sm="auto">
              <RefinementButton
                onClick={() => {
                  dispatch(setIsRefinementModalOpen(true));
                }}
              />
            </Grid>
          )}
        {showStartMonitoringButton && (
          <Grid item xs={12} sm="auto">
            <Button
              isFullWidth
              buttonType="secondary"
              onClick={() => startMonitoring()}
              leftIcon={<PlayIcon />}
              isLoading={isStartingMonitoring}
            >
              Start monitoring
            </Button>
          </Grid>
        )}
        {showSendActivationEmail && (
          <Grid item xs={12} sm="auto">
            <Button
              isFullWidth
              buttonType="secondary"
              onClick={() => sendActivationEmail()}
              isLoading={isSendingActionEmail}
            >
              Resend activation email
            </Button>
          </Grid>
        )}
        {mobileSize && (
          <Grid item xs={12} sm="auto">
            <Button
              isFullWidth
              buttonType="secondary"
              onClick={() => dispatch(setIsProductSelectionModalOpen(true))}
              leftIcon={<PlusIcon />}
            >
              Place an order
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm="auto">
          <ActionButton
            aria-describedby={popOverId}
            isShort
            buttonType="black-outline"
            rightIcon={<ArrowDown />}
            isLoading={isLoading || isTransistionJourneySubmitting}
            onClick={handleOpenPopOver}
            isFullWidth
          >
            Actions
          </ActionButton>
        </Grid>
        <Popover
          id={popOverId}
          open={isPopOverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopOver}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <AdditionalItemsMenu>
            {filteredActions.map((action) => {
              //If the case type isn't correct
              if (
                !actionConfigs[action].allowedCaseTypes.includes(
                  selectedCase?.caseType?.name
                )
              ) {
                return null;
              }

              //If the provider state isn't correct
              if (
                !actionConfigs[action].allowedStatuses.includes(
                  selectedCase?.caseState?.providerFacing
                )
              ) {
                return null;
              }

              // Replace monitoring label base off brand
              const label =
                action == ACTIONS.VIEW_IN_CANDID_MONITORING
                  ? `View in ${monitoringLabel}`
                  : action;

              const onClickFunc = actionOnClickMap[action];
              return (
                <LinkItem
                  key={action}
                  onClick={() => {
                    handleClosePopOver();
                    onClickFunc();
                  }}
                >
                  {actionConfigs[action].icon}
                  <button data-testid="pdp-case-status-action">{label}</button>
                </LinkItem>
              );
            })}
          </AdditionalItemsMenu>
        </Popover>
      </Grid>
      <ActionsModals {...actionModalsProps} />
    </ActionGroupContainer>
  );
};

export default Actions;
